<template>
    <div class="page-content white-bg">
        
        <div class="">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item>法院导航</el-breadcrumb-item>
            </el-breadcrumb>
           
            <ul class="nav-list flex">
                <li
                    class="hand"
                    v-for="(item, index) in footNav"
                    :key="index"
                    @click="skipNav(item)"
                >
                    <img :src="item.img" />
                    <div class="desc flex1">
                        {{ item.desc }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "court",
    data() {
        return {
            
            footNav: [
                {
                    img: require("@/assets/images/footer-icon01.png"),
                    title: "消费金融平台",
                    desc: "中国庭审直播公开",
                    url: "http://tingshen.court.gov.cn/",
                },
                {
                    img: require("@/assets/images/footer-icon02.png"),
                    title: "小额贷款平台",
                    desc: "中国审判流程信息公开",
                    url: "https://splcgk.court.gov.cn/gzfwww/",
                },
                {
                    img: require("@/assets/images/footer-icon03.png"),
                    title: "风险决策平台",
                    desc: "中国裁判文书公开",
                    url: "https://wenshu.court.gov.cn/",
                },
                {
                    img: require("@/assets/images/footer-icon04.png"),
                    title: "风险决策平台",
                    desc: "中国执行信息公开",
                    url: "http://zxgk.court.gov.cn/",
                },
                {
                    img: require("@/assets/images/footer-icon05.png"),
                    title: "P2P网贷平台",
                    desc: "全国法院统一达到平台",
                    url: "https://songda.court.gov.cn/",
                },
                {
                    img: require("@/assets/images/footer-icon06.png"),
                    title: "风险决策平台",
                    desc: "最高人民法院诉讼服务网",
                    url: "https://ssfw.court.gov.cn/ssfww/",
                },
            ],
        };
    },
    methods: {
        skipNav(item) {
            window.open(item.url);
        },
    },
};
</script>
<style lang="less" scoped>
.page-content {
    width: 1060px;
    margin: 20px auto;
    .el-breadcrumb {
        padding: 20px;
        border-bottom: 1px solid #f4f4f4;
    }
    .nav-list {
        width: 100%;
        flex-flow: wrap;
		padding: 20px 0 ;
    }

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 265px;
        border: 1px solid #d6d6d6;
        padding: 28px 0;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: 65px;
    }

    img {
        width: 47px;
        height: 47px;
        border-radius: 50%;
    }

    .desc {
        color: #323232;
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
        padding: 0 40px;
    }
}
</style>
